

const BASE_URL = 'https://customsearch.googleapis.com/customsearch/v1';
const API_KEY = 'AIzaSyBbI5KNxleD0ApN4Zh7O8nFnLf9y2oyuOQ';
const SEARCH_ENGINE_ID = '000111541876472553213:sqiap2fyy9u';

export interface SearchResult {
  totalResults: number;
  pageNumber: number;
  numberOfPages: number;
  items: SearchResultItem[];
};

export interface SearchResultItem {
  htmlTitle: string;
  link: string;
  htmlFormattedUrl: string;
  htmlSnippet: string;
}

export default class SearchService {

  static async search(query: string, pageNumber=1): Promise<SearchResult> {

    let url = `${BASE_URL}?key=${API_KEY}&cx=${SEARCH_ENGINE_ID}&q=${encodeURIComponent(query)}`;
    if (pageNumber !== 1) {
      const startIndex = (pageNumber - 1) * 10 + 1;
      url += `&start=${startIndex}`;
    }
    const response = await fetch(url);
    if (response.status !== 200) {
      const body = response.text ? await response.text() : '';
      console.error(`The search server responded with error code ${response.status}. Response body: ${body}`)
      throw new Error(`The search server responded with error code ${response.status}.`);
    }
    const body = await response.json();
    let items = [];
    if (Array.isArray(body.items)) {
      items = body.items.map(i => ({
        htmlTitle: i.htmlTitle,
        link: i.link,
        htmlFormattedUrl: i.htmlFormattedUrl,
        htmlSnippet: i.htmlSnippet
      }));
    }

    const totalResults = parseInt(body.searchInformation.totalResults);

    return {
      totalResults,
      pageNumber: Math.floor((body.queries.request[0].startIndex - 1) / 10) + 1,
      numberOfPages: Math.ceil(totalResults / 10),
      items
    };
  }
}
