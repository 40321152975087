import React, { Component } from 'react';
import Spinner from '../../web-common/components/Spinner';
import toQueryString from '../../web-common/utils/toQueryString';
import parseQueryString from '../../web-common/utils/parseQueryString';
import JumboSearch from '../JumboSearch';
import SupportNavbar from '../SupportNavbar';
import Seo from '../Seo';
import SearchService, { SearchResult, SearchResultItem } from '../../services/SearchService';
import './styles.scss';

interface ArticleLinkSection {
  title?: string;
  links: ArticleLink[];
}

interface ArticleLink {
  text: string;
  url: string;
}

interface SearchPageProps {
  pageContext: {
    articleLinkSections: ArticleLinkSection[]
  }
}

export default class SearchPage extends Component<SearchPageProps> {

  state = {
    error: '',
    searchPhrase: '',
    searchInProgress: false,
    searchResult: undefined as SearchResult|undefined
  }

  componentDidMount() {

    window.addEventListener('popstate', this._handlePopState);
    const { q, pageNumber } = parseQueryString(location.search);
    if (q) {
      this._submitSearch(q, pageNumber ? parseInt(pageNumber) : undefined);
    }
  }

  render() {
    return (
      <div className="search-page">
        <Seo title="Search documentation" description="Documentation search for Vuplex 3D WebView" />
        <div className="search-top-section">
          <SupportNavbar/>
          <JumboSearch
            className="search-page-input"
            value={this.state.searchPhrase}
            onChange={this._handleSearchPhraseChange}
            onSubmit={this._handleSearchSubmit}
            disabled={this.state.searchInProgress}
            placeholder="Search documentation and articles"
          />
        </div>
        {this._renderSearchResult()}
      </div>
    );
  }

  private _handlePaginationClick = (event) => {

    const pageNumber = parseInt(event.target.innerText);
    this._submitSearch(this.state.searchPhrase, pageNumber);
  };

  private _handlePopState = (event) => {

    const { q='', pageNumber: pageNumberString } = parseQueryString(location.search);
    const queryMatches = q === this.state.searchPhrase;

    const pageNumber = pageNumberString ? parseInt(pageNumberString) : undefined;
    const pageNumberMatches = (this.state.searchResult && this.state.searchResult.pageNumber) === pageNumber;

    if (!(queryMatches && pageNumberMatches)) {
      if (q.trim()) {
        this._submitSearch(q, pageNumber, true);
      } else {
        this.setState({ searchPhrase: '', searchResult: undefined, error: '' });
      }
    }
  };

  private _handleSearchSubmit = (query: string) => this._submitSearch(this.state.searchPhrase);

  private _handleSearchPhraseChange = (event) => this.setState({ searchPhrase: event.target.value });

  private _renderArticleLinkSection = (section: ArticleLinkSection) => (
    <div key={section.title}>
      {section.title && <h2>{section.title}</h2>}
      <ul>
        {section.links.map(this._renderArticleLink)}
      </ul>
    </div>
  );

  private _renderArticleLink = (link: ArticleLink) => (
    <li key={link.url}>
      <a href={link.url}>{link.text}</a>
    </li>
  );

  private _renderPaginationLink = (pageNumber: number) => {

    const isCurrentPage = pageNumber === this.state.searchResult!.pageNumber;
    const className = isCurrentPage ? 'current-page' : undefined;
    const clickHandler = isCurrentPage ? undefined : this._handlePaginationClick;
    return (
      <a key={pageNumber} className={className} onClick={clickHandler}>{pageNumber}</a>
    );
  };

  private _renderMessage(message: string) {

    return (
      <div className="bottom-container">
        <div className="search-message">
          <h2>{message}</h2>
        </div>
      </div>
    );
  }

  private _renderSearchResult = () => {

    const { error, searchResult } = this.state;

    if (error) {
      return (
        <div className="bottom-container">
          <div className="error">
            <h2>Yikes! An unexpected error occurred.</h2>
            Error message: {error}
          </div>
        </div>
      );
    }

    if (searchResult) {

      if (searchResult.totalResults === 0) {
        return this._renderMessage('No results');
      }

      const paginationPageNumbers = [] as number[];
      for (let i = 1; i <= searchResult.numberOfPages; i++) {
        paginationPageNumbers.push(i);
      }
      return (
        <div className="search-results">
          <div className="result-count">
            <span>About {searchResult.totalResults} results</span>
          </div>
          <div className="result-list">
            {searchResult.items.map(this._renderSearchResultItem)}
          </div>
          <div className="pagination">
            {paginationPageNumbers.map(this._renderPaginationLink)}
          </div>
        </div>
      )
    }

    if (this.state.searchInProgress) {
      return (
        <div className="bottom-container">
          <Spinner color="#12bae9"/>
        </div>
      )
    }

    return (
      <div className="top-articles">
        <h1>Support articles / FAQ</h1>
        <div>
          {this.props.pageContext.articleLinkSections.map(this._renderArticleLinkSection)}
        </div>
      </div>
    )
  };

  private _renderSearchResultItem = (item: SearchResultItem) => {
    return (
      <div className="result-item" key={item.link}>
        <a dangerouslySetInnerHTML={{ __html: item.htmlTitle }} href={item.link}/>
        <div className="result-url" dangerouslySetInnerHTML={{ __html: item.htmlFormattedUrl }}/>
        <div className="result-snippet" dangerouslySetInnerHTML={{ __html: item.htmlSnippet }}/>
      </div>
    );
  }

  private async _submitSearch(searchPhrase: string, pageNumber?: number, bypassUrlUpdate?: boolean) {
    try {
      if (!bypassUrlUpdate) {
        const queryString = toQueryString({ q: searchPhrase, pageNumber });
        history.pushState({}, '', `/search?${queryString}`);
      }

      this.setState({ searchPhrase, searchInProgress: true });
      const searchResult = await SearchService.search(searchPhrase, pageNumber);
      this.setState({
        searchInProgress: false,
        searchResult,
        error: ''
      });
    } catch (error) {
      console.error(`An error occurred while getting search results: ${error.message}`);
      this.setState({
        searchInProgress: false,
        searchResult: undefined,
        error: error.message
      });
    }
  }
}
