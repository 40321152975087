
export default function toQueryString(params: { [key: string]: string|undefined|null|number|boolean }): string {

  const keyValuePairs = Object.keys(params)
                              .filter(key => params[key] !== undefined && params[key] !== null)
                              .map(key => {
    return `${encodeURIComponent(key)}=${encodeURIComponent(new String(params[key]).toString())}`;
  });
  return keyValuePairs.join('&');
}
