import React, { Component } from 'react';
import Sidebar, { SidebarProps } from '../../web-common/components/Sidebar';
import './styles.scss';

export default class SupportSidebar extends Component<SidebarProps> {

  render() {
    return (
      <div className="support-sidebar">
        <Sidebar {...this.props} mobileOnly={true}>
          <a href="https://developer.vuplex.com">Developer site</a>
          <a href="https://blog.vuplex.com">Blog</a>
          <a href="https://github.com/vuplex">GitHub</a>
          <a href="https://vuplex.com/contact">Contact</a>
        </Sidebar>
      </div>
    );
  }
}
