import React, { Component, KeyboardEvent } from 'react';
import './styles.scss';

interface IFrameResult {
  status: 'PASS' | 'FAIL' | 'WARN'
  message: string;
  url: string;
}

export default class IFrameTestPage extends Component {

  state = {
    url: '',
    // result: { status: 'WARN', message: 'This URL can be loaded in an iframe because the server did not respond with an X-Frame-Options header.', url: 'https://www.google.com'}
    result: null as IFrameResult|null,
    isSending: false
  };

  render() {
    return (
      <div className="iframe-test">
        <div>
          <input
            placeholder="https://www.example.com"
            type="text"
            onChange={this._handleInputChange}
            onKeyDown={this._handleInputKeyDown}
            disabled={this.state.isSending}/>
          <button
            onClick={this._submit}
            disabled={this.state.isSending || this.state.url.split('.').filter(str => !!str).length < 2}>
              Test URL
          </button>
        </div>
        {this._renderResult()}
      </div>
    );
  }

  private _handleInputChange = (event) => {
    this.setState({ url: event.target.value });
  }

  private _handleInputKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'Enter') {
      this._submit();
    }
  };

  private _renderResult() {

    const { result } = this.state;
    if (!result) {
      return;
    }
    const emoji = result.status === 'PASS' ? '✅' : result.status === 'FAIL' ? '⛔️' : '⚠️';
    return (
      <div className={`result ${result.status.toLowerCase()}`}>
        <div>
          <div className="emoji">{emoji}</div>
          <div>
            {result.message}
            <div className="url">
              {result.url}
            </div>
          </div>
        </div>
      </div>
    );
  }

  private _submit = async () => {

    this.setState({ isSending: true });
    try {
      const response = await fetch(`https://api.vuplex.com/support/webview/webgl/iframe-test?url=${encodeURIComponent(this.state.url)}`);
      const result = await response.json();
      this.setState({ result, isSending: false });
    } catch (error) {
      this.setState({
        result: {
          status: 'FAIL',
          message: `An error occurred while sending the request: ${error}`,
          url: this.state.url
        },
        isSending: false
      });
    }
    // Reach up into the parent frame to resize this iframe to a height of 250px
    // to accomodate the result.
    const iframeElement = window.parent.document.getElementById('iframe-test');
    if (iframeElement) {
      iframeElement.style.height = '250px';
    }
  };
}
