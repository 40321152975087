import React, { Component } from 'react';
import toQueryString from '../../web-common/utils/toQueryString';
import isValidEmailAddress from '../../web-common/utils/isValidEmailAddress';
import Spinner from '../../web-common/components/Spinner';
import ContactService from '../../services/ContactService';
import Seo from '../Seo';
import JumboSearch from '../JumboSearch';
import SupportNavbar from '../SupportNavbar';
import './styles.scss';

enum MessageState {
  UNSENT,
  SENDING,
  SENT,
  ERROR,
};

export default class ContactPage extends Component {

  state = {
    searchPhrase: '',
    email: '',
    message: '',
    emailIsValid: false,
    messageIsValid: false,
    messageState: MessageState.UNSENT
  }

  render() {
    return (
      <div className="contact-page">
        <Seo title="Contact" description="Get support for Vuplex 3D WebView" />
        <div className="contact-top-section">
          <SupportNavbar/>
          <h1>Contact</h1>
        </div>
        <div className="contact-content">
          <p className="search-description">
            <strong>Have a question?</strong> A quick search often provides the fastest answer:
          </p>
          <JumboSearch
            className="contact-search"
            value={this.state.searchPhrase}
            onChange={this._handleSearchPhraseChange}
            onSubmit={this._handleSearchSubmitted}
            placeholder="Search documentation and articles"
          />
          <p className="troubleshooting">
            <a href="https://developer.vuplex.com/webview/troubleshooting">The troubleshooting page</a> also has helpful tips for resolving issues.
          </p>
          <hr/>
          {this._renderContactForm()}
        </div>
      </div>
    );
  }

  private _handleSearchPhraseChange = (event) => this.setState({ searchPhrase: event.target.value });

  private _handleSearchSubmitted = (searchPhrase) => {
    const queryString = toQueryString({ q: searchPhrase });
    location.href = `/search?${queryString}`;
  };

  private _handleButtonClick = async () => {
    try {
      const { email, message } = this.state;
      this.setState({ messageState: MessageState.SENDING });
      await ContactService.sendMessage({ email, message });
      this.setState({ messageState: MessageState.SENT });
    } catch (error) {
      console.log('error: ' + error.message);
      this.setState({ messageState: MessageState.ERROR });
    }
  }

  private _handleEmailChange = (event) => {

    const { value } = event.target;
    this.setState({
      email: value,
      emailIsValid: isValidEmailAddress(value)
    });
  }

  private _handleMessageChange = (event) => {

    const { value } = event.target;
    this.setState({
      message: value,
      messageIsValid: !!value.trim()
    });
  }

  private _renderContactFormContent() {
    return (
      <div>
        <p className="contact-description">
          Still no luck? Send a message, and I'll get back to you soon:
        </p>
        {/* <p className="out-of-office">
          Note: I will be out of office from December 24th to January 2nd and will have limited access to email during that time.
          You can still send me a message, though, and I will get back to you the following week.
        </p> */}
        <div className="row">
          <div className="row-name">
            email
          </div>
          <input type="email"
            placeholder="your.email@example.com"
            value={this.state.email}
            onChange={this._handleEmailChange} />
        </div>
        <div className="message-row">
          <div className="row-name">
            message
          </div>
          <textarea placeholder="Hello!"
            value={this.state.message}
            onChange={this._handleMessageChange} />
        </div>
        <div className="button-row">
          <button disabled={!(this.state.emailIsValid && this.state.messageIsValid)} onClick={this._handleButtonClick}>Send</button>
        </div>
      </div>
    );
  }

  private _renderContactForm() {

    switch (this.state.messageState) {
      case MessageState.UNSENT:
        return this._renderContactFormContent();
      case MessageState.SENDING:
        return this._renderSendingMessage();
      case MessageState.SENT:
        return (
          <div className="sent-message">
            <h2>Thanks!</h2>
            <p>I'll get back to you soon.</p>
          </div>
        );
      case MessageState.ERROR:
        return (
          <div className="sent-message">
            <h2>Yikes!</h2>
            <p>Sorry, but there was an error. Please try emailing me at nate@vuplex.com instead.</p>
          </div>
        );
    }
  }

  private _renderSendingMessage() {
    return (
      <div className="sending-message">
        <h2>Sending...</h2>
        <Spinner size="100px" />
      </div>
    );
  }
}
