import React from 'react';
import Navbar, { MobileMenuButtonOptions } from '../web-common/components/Navbar';

interface SupportNavbarProps {
  search?: boolean;
  mobileMenuButton?: MobileMenuButtonOptions;
}

const SupportNavbar = (props: SupportNavbarProps) => (
  <Navbar
    siteName="support"
    centerLinks={[
      <a key="dev" href="https://developer.vuplex.com">developers</a>,
      <a key="blog" href="https://blog.vuplex.com">blog</a>,
      <a key="contact" href="/contact">contact</a>
    ]}
    search={props.search}
    mobileMenuButton={props.mobileMenuButton}/>
);

export default SupportNavbar;
