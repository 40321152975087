import React, { Component } from 'react';
import searchImage from '../../web-common/assets/search.svg';
import './styles.scss';

interface JumboSearchProps {
  value: string;
  className?: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onSubmit: (query: string) => void;
  placeholder:string;
  disabled?: boolean;
}

export default class JumboSearch extends Component<JumboSearchProps> {

  render() {
    return (
      <div className={`jumbo-search ${this.props.className || ''}`}>
        <input
          value={this.props.value}
          type="search"
          onChange={this.props.onChange}
          onKeyDown={this._handleSearchKeyDown}
          placeholder={this.props.placeholder}
          disabled={this.props.disabled}
        />
        <button onClick={this._handleSearchButtonClick} disabled={!this.props.value.trim().length || this.props.disabled}>
          <img src={searchImage} alt="search" />
        </button>
      </div>
    );
  }

  private _handleSearchButtonClick = () => this.props.onSubmit(this.props.value);

  private _handleSearchKeyDown = (event) => {
    // Submit the search when Enter is pressed.
    if (event.keyCode === 13 && this.props.value.trim().length) {
      this.props.onSubmit(this.props.value);
    }
  }
}
