import React, { Component } from 'react';
import ReactMarkdown from 'react-markdown/with-html';
import hljs from 'highlight.js';
import javascript from 'highlight.js/lib/languages/javascript';
import cs from 'highlight.js/lib/languages/csharp';
import Article from '../../models/Article';
import formatDate from '../../web-common/utils/formatDate';
import Seo from '../Seo';
import SupportNavbar from '../SupportNavbar';
import SupportSidebar from '../SupportSidebar';
import authorImage from './assets/nate.jpg';
import 'highlight.js/styles/mono-blue.css';
import '../../index.scss';
import './styles.scss';

hljs.registerLanguage('javascript', javascript);
hljs.registerLanguage('cs', cs);
hljs.configure({ languages: ['javascript', 'cs', 'html'] });

export default class ArticlePage extends Component<{ pageContext: { article: Article } }> {

  state = {
    mobileSidebarOpen: false
  }

  componentDidMount() {

    hljs.initHighlightingOnLoad();
    this._highlightCode();
  }

  render() {

    const date = this._article.updatedDate || this._article.createdDate;

    return (
      <div className="article-page" ref={this._saveRef}>
        <Seo title={this._article.title} description={this._article.description}/>
        <SupportSidebar mobileSidebarOpen={this.state.mobileSidebarOpen} onMobileBackdropClick={this._handleMobileMenuButtonClick}/>
        <div className="article-top-section">
          <SupportNavbar
            search={true}
            mobileMenuButton={{ onClick: this._handleMobileMenuButtonClick, menuOpen: this.state.mobileSidebarOpen }}/>
          <h1>{this._article.title}</h1>
          <div className="article-info-container">
            <div className="article-info">
              <img src={authorImage} alt="Nate Hill"/>
              <div>
                <address>Nate Hill</address>
                <div className="date">
                  {this._article.updatedDate && <span>Updated </span>}
                  <time dateTime={date}>{formatDate(date, { abbreviateMonth: true })}</time>
                </div>
              </div>
            </div>
          </div>
        </div>
        <article>
          {this._renderSymptoms()}
          {this._renderCause()}
          {this._renderResolution()}
          {this._article.redirect && <p style={{ textAlign: 'center' }}>Redirecting...</p>}
          {this._article.content && <div className="content"><ReactMarkdown source={this._article.content.trim()} escapeHtml={false} /></div>}
          {this._renderSeeAlso()}
        </article>
      </div>
    );
  }

  private get _article(): Article {
    return this.props.pageContext.article;
  }

  private _componentElement: any;

  private _handleMobileMenuButtonClick = () => this.setState({ mobileSidebarOpen: !this.state.mobileSidebarOpen });

  private _highlightCode() {

    const nodes = this._componentElement.querySelectorAll('pre code');
    for (let i = 0; i < nodes.length; i++) {
      hljs.highlightBlock(nodes[i])
    }
  }

  private _renderSymptoms() {

    const { symptoms } = this._article;
    if (!symptoms) {
      return;
    }
    return (
      <div>
        <h3>Symptoms</h3>
        <ReactMarkdown source={symptoms.trim()} escapeHtml={false} />
      </div>
    )
  }

  private _renderCause() {

    const { cause } = this._article;
    if (!cause) {
      return;
    }
    return (
      <div>
        <h3>Cause</h3>
        <ReactMarkdown source={cause.trim()} escapeHtml={false} />
      </div>
    )
  }

  private _renderResolution() {

    const { resolution } = this._article;
    if (!resolution) {
      return;
    }
    return (
      <div>
        <h3>Resolution</h3>
        <ReactMarkdown source={resolution.trim()} escapeHtml={false} />
      </div>
    )
  }

  private _renderSeeAlso() {

    const { seeAlso } = this._article;
    if (!seeAlso || !seeAlso.length) {
      return;
    }
    if (Array.isArray(seeAlso)) {
      return (
        <div className="seealso">
          <strong>See also:</strong>
          <ul>
            {seeAlso.map(item => <li key={item}><ReactMarkdown source={item} /></li>)}
          </ul>
        </div>
      );
    }
    return <ReactMarkdown className="seealso" source={`**See also**: ${seeAlso}`} />;
  }

  private _saveRef = (componentElement) => this._componentElement = componentElement;
}
