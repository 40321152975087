// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-components-search-page-index-tsx": () => import("/Users/nate/repos/vx/vuplex-web/support.vuplex.com/src/components/SearchPage/index.tsx" /* webpackChunkName: "component---src-components-search-page-index-tsx" */),
  "component---src-components-contact-page-index-tsx": () => import("/Users/nate/repos/vx/vuplex-web/support.vuplex.com/src/components/ContactPage/index.tsx" /* webpackChunkName: "component---src-components-contact-page-index-tsx" */),
  "component---src-components-article-page-index-tsx": () => import("/Users/nate/repos/vx/vuplex-web/support.vuplex.com/src/components/ArticlePage/index.tsx" /* webpackChunkName: "component---src-components-article-page-index-tsx" */),
  "component---src-components-redirect-page-tsx": () => import("/Users/nate/repos/vx/vuplex-web/support.vuplex.com/src/components/RedirectPage.tsx" /* webpackChunkName: "component---src-components-redirect-page-tsx" */),
  "component---node-modules-gatsby-plugin-s-3-fake-index-js": () => import("/Users/nate/repos/vx/vuplex-web/support.vuplex.com/node_modules/gatsby-plugin-s3/fake-index.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-s-3-fake-index-js" */),
  "component---src-pages-iframe-test-index-tsx": () => import("/Users/nate/repos/vx/vuplex-web/support.vuplex.com/src/pages/iframe-test/index.tsx" /* webpackChunkName: "component---src-pages-iframe-test-index-tsx" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/Users/nate/repos/vx/vuplex-web/support.vuplex.com/.cache/data.json")

