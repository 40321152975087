
const BASE_URL = 'https://api.vuplex.com';

export default class ContactService {

  static async sendMessage(options: { email: string, message: string }) {

    const { email, message } = options;
    const response = await fetch(`${BASE_URL}/communication/message`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ email, message })
    });

    if (!response.ok) {
      throw new Error(`Status code: ${response.status}`);
    }
  }
}
